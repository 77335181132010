import React, { useEffect, useState } from 'react';
import { FiMapPin } from 'react-icons/fi';
import { Box, Popover, PopoverTrigger, Portal, Spinner, useDisclosure } from '@chakra-ui/react';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { updateUser } from '~/lib/user/slice';

import { AcceptCity } from './accept-city';
import { SelectCity } from './select-city';

import styles from './city-block.module.css';

export const CityBlock = () => {
    const dispatch = useAppDispatch();

    const selectedCity = useAppSelector((state) => state.user.data.address?.name ?? '');
    const [isSelectOtherCity, setIsSelectOtherCity] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(true);
    const [sessionCity, setSessionCity] = useState('');

    const handleSelectOtherCity = () => {
        setIsSelectOtherCity(true);
    };

    useEffect(() => {
        const storedCity = sessionStorage.getItem('city');

        if (storedCity) {
            dispatch(updateUser({ address: JSON.parse(storedCity) }));
            setSessionCity(JSON.parse(storedCity));
        }
        setLoading(false);
    }, [dispatch]);

    useEffect(() => {
        if (!sessionCity && !loading) {
            onOpen();
        }
    }, [sessionCity, loading, onOpen]);

    return (
        <>
            <Box pl='0' pr='0'>
                {loading ? (
                    <Spinner size='md' color='rgba(235, 241, 237, 1)' />
                ) : (
                    <Popover offset={[0, 50]} isOpen={isOpen} placement='bottom-start' autoFocus={false} onClose={onClose} returnFocusOnClose={false}>
                        <PopoverTrigger>
                            <ButtonCore leftAddon={<FiMapPin />} view='ghost' size='small' className={styles.button} onClick={onOpen}>
                                <TypographyCore view='medium' size='m'>
                                    г. {selectedCity || 'Санкт-Петербург'}
                                </TypographyCore>
                            </ButtonCore>
                        </PopoverTrigger>
                        <Portal>
                            {isSelectOtherCity || sessionCity ? (
                                <SelectCity onClose={onClose} setIsSelectOtherCity={setIsSelectOtherCity} setSessionCity={setSessionCity} />
                            ) : (
                                <AcceptCity onClose={onClose} onSelectOtherCity={handleSelectOtherCity} setSessionCity={setSessionCity} />
                            )}
                        </Portal>
                    </Popover>
                )}
            </Box>
        </>
    );
};
